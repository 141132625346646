import { IGenericAccount } from "./../components/models/YapilyModels/IGenericAccount";
import { autoinject } from "aurelia-framework"; //FAKE NEWS proceed
import { P2PWallet } from "bsv-wallet";
import { Crypto } from "bsv-wallet";
import { User } from "components/models/UserModel";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import localforage from "localforage";
import { SingletonService } from "singleton";
import bsv from "./lib/bsv";
import { PaymailWalletClient } from "http_clients/PaymailWalletClient";
import { connectTo } from "aurelia-store";
import { State } from "state";

// DO THE OTHER WAY AROUND
// GO TO ALL THE PAGES THAT CALL WALLET.MANAGER SERVICE
// AND HAVE IT CALL HERE; BUT WITH NEW FUNCTIONS
// This walletmanager should call PaymailAPI and ask for all transactionHistory
// And should also get all wallet info from Wallets (already here almost)
// we need new httpEndpoint for new microservice Paymail
// We need new microservice Paymail to be up and running with database migration (just for now I know we will remigrate)
// @dsp28 plz @hatredb plz
// In this way we don't have 125 errors that won't allow us to continue working.

export class Wallet implements IGenericAccount {
  name: string;
  balance: number;
  paymail: string;
  identification: string;
  picture: string = "";
  isBlockchain: boolean;
  currency: string;
  selected?: boolean;
  id?: string;
  isError: boolean;

  constructor() {
    this.identification = this.paymail;
    this.isBlockchain = true;
    this.currency = "BSV";
  }
}

export type WalletInfo = {
  name: string;
  balance: number;
  paymail: string;
  selected?: boolean;
  id?: string;
};

/**
 * Handle wallets
 */

@connectTo()
@autoinject()
export class WalletsManager {

  private state: State;
  private wallets: Map<string, P2PWallet>;
  private owner_id: string;
  private me: User;

  constructor(
    private paymailWalletHttpClient: PaymailWalletClient,
    private singleton: SingletonService
  ) {
  }

  bind() {
    this.me = this.state.me;
    this.loadWallets();
  }

  /**
   * Return wallet with given name
   */

  async getReceiveTopUpInfo(paymail) {
    const wallet = new P2PWallet();
    let info = await wallet.getOutputsFromPaymailServerForDesiredAmount(
      paymail
    );
    return info;
  }

  get(name: string) {
    return this.wallets.get(name);
  }

  set(name: string, walletUpdate: P2PWallet) {
    if (this.get(name)) {
      this.remove(name);
    }

    this.save(name, walletUpdate);
  }

  /**
   * Add a wallet to wallets list
   */
  async save(name: string, wallet: P2PWallet) {
    this.wallets.set(name, wallet);
    this.saveWallets();
  }

  /**
   * To import from mnemonic
   */
  async importFromMnemonic(name: string, mnemonic: string[]) {
    const wallet = new P2PWallet({ key: mnemonic.join(" ") } as any);
    this.save(name, wallet);
    return wallet;
  }

  /**
   * Remove a wallet from wallets list
   */
  remove(name) {
    this.wallets.delete(name);
    this.saveWallets();
  }

  /**
   * Return all wallets from store
   */
  getAll() {
    return this.wallets;
  }

  /**
   * Return name, address and balance for every wallet
   */
  async getWalletsInfos(): Promise<WalletInfo[]> {
    this.me = this.singleton.getMe();
    await this.loadWallets();
    const infos: WalletInfo[] = [];
    const sortedWallets = Array.from(this.wallets.entries());
    const promises = sortedWallets.map(async ([name, wallet]) => {
      const balance = wallet.getBalance();
      const paymail = await this.getPaymailFromName(name);
      infos.push({ name, balance, paymail, id: wallet.id });
    });

    await Promise.all(promises);

    return infos;
  }

  async getPaymailFromName(name) {
    try {
      let request = await this.paymailWalletHttpClient.fetch(
        "/wallet/name" + name
      );
      let is200OK = await checkResponseStatus(request);
      let reponse = await is200OK.json();
      if (typeof reponse !== "string") reponse.paymail = "unknown";
      if (reponse === "Wallet has been deleted") {
        return;
      }
      return reponse;
    } catch (e) {
      console.log(e);
    }
  }

  /**
   * Register all wallets in localStorage
   */
  private async saveWallets() {
    const obj = {};

    this.wallets.forEach((wallet: P2PWallet, name) => {
      obj[name] = wallet.getPrivateKey();
    });

    let nameInStorage = "wallets_" + this.me._id;
    await localforage.setItem(nameInStorage, JSON.stringify(obj));
  }

  /**
   * Load all wallets from localStorage
   */
  public async loadWallets() {
    try {
      let nameInStorage = "wallets_" + this.me._id;
      const wallets = JSON.parse(await localforage.getItem(nameInStorage));

      if (!this.wallets) {
        this.wallets = new Map();
      }

      const processedWallets = [];

      await Promise.all(
        Object.keys(wallets).map(async (name) => {
          const key = wallets[name];
          const wallet = new P2PWallet({ key: key, network: "livenet" } as any);
          let { xpubIndex, _id } = await this.getWalletInfoFromBE(name);
          let currentWalletObject = wallet;
          currentWalletObject.lastUsedIndex = await xpubIndex;
          currentWalletObject.id = _id;
          this.set(name, currentWalletObject);
          this.wallets.set(name, wallet);
          processedWallets.push({ name, wallet: currentWalletObject });
        })
      );

      processedWallets.sort(
        (a, b) => a.wallet.lastUsedIndex - b.wallet.lastUsedIndex
      );

      processedWallets.forEach(({ name, wallet }) => {
        this.set(name, wallet);
        this.wallets.set(name, wallet);
      });
    } catch (e) {
      console.log(e);
    }
  }

  async getWalletInfoFromBE(name) {
    try {
      let request = await this.paymailWalletHttpClient.fetch(
        `/wallet/name/${name}`
      );
      let is200Ok = await checkResponseStatus(request);
      let wallet = await is200Ok.json();
      if ((wallet.message = "Wallets not found")) {
        wallet = [];
        return wallet;
      }
      return wallet;
    } catch (e) {
      console.log(e);
    }
  }

  async getUtxoFromBackEndForAllWallet() {}

  async patchUtxoFromBackEnd(utxo) {}
}

import ENDPOINTS from "endpoints";
import { WebSocketService } from "services/websocket.service";
import { autoinject } from "aurelia-framework";
import { HttpClient } from "aurelia-fetch-client";
import { EventAggregator, Subscription } from "aurelia-event-aggregator";
import { Store, connectTo } from "aurelia-store";
import { State } from "state";
import { WebSocketRequestModel } from "./models/WebSocketRequestModel";
import { default as env } from "../../config/environment.json";
import { AuthService } from "aurelia-auth";
import { WalletModel } from "./models/WalletModel";
import { IGenericAccount } from "components/models/YapilyModels/IGenericAccount";
import { YapilyBankModel } from "components/models/YapilyModels/YapilyBankModel";
import { YapilyBankDetailsModel } from "components/models/YapilyModels/YapilyBankDetailsModel";
import { YapilyAccountsModel } from "components/models/YapilyModels/YapilyAccountsModel";
import { IGenericTransaction } from "components/models/YapilyModels/IGenericTransaction";
import { checkResponseStatus } from "http_clients/checkResponseStatus";
import { I18N } from "aurelia-i18n";
import { YapilyTransactionModel } from "components/models/YapilyModels/YapilyTransactionModel";
import { YapilyHttpClient } from "http_clients/YapilyHttpClient";
import localforage from "localforage";

@connectTo()
@autoinject
export class YapilyService {
  private subscriptions: Array<Subscription> = [];
  private banks: YapilyBankModel[] = [];

  constructor(
    private http: HttpClient,
    private eventAggregator: EventAggregator,
    private store: Store<State>,
    private authService: AuthService,
    private webSocketService: WebSocketService,
    private yapilyHttpClient: YapilyHttpClient,
    private i18n: I18N
  ) {
    this.subscriptions.push(
      this.eventAggregator.subscribe("app.started", () => this.getBanks())
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "banks.add.many",
        (banks: YapilyBankModel[]) => this.updateBanks(banks)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe("banks.add.one.backend", (links) =>
        this.postNewConsent(links)
      ),
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "banks.details.add.one",
        (bankDetail: YapilyBankDetailsModel) =>
          this.updateBankDetails(bankDetail)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "bank.accounts.add.many",
        (bankAccounts: YapilyAccountsModel) =>
          this.updateAccountsFromBank(bankAccounts)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "banks.delete.one.backend",
        (account: IGenericAccount) =>
          this.deleteBank(account)
      )
    );
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        "banks.delete.one.state",
        (accountsId: string[]) =>
          this.deleteBankState(accountsId)
      )
    );
  }


  /**p
   * This function gets the wallets from the backend
   * will try websocket first, will fall back on ordinary http calls
   *
   */
  async getBanks() {
    const verb = "GET";
    const url = `${env.yapily_api}${ENDPOINTS.ALL_BANKS()}`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getBanksRequest = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getBanksRequest);
  }

  /**
   * Filling up a local banks variable
   * still need to wait for the associated accounts in there
   * and later for the transactions for those accounts
   * @param banks: All banks received from WS
   */
  updateBanks(banks: YapilyBankModel[]) {
    this.getBanksDetails(this.banks);
  }

  /**
   * Gets the details about a bank
   */
  async getBanksDetails(banks: YapilyBankModel[]) {
    for (let bank of banks) {
      const verb = "GET";
      const url = `${env.yapily_api}${ENDPOINTS.SPECIFIC_BANK(
        bank.institution
      )}`;
      const body = "";
      const token = localStorage.getItem("aurelia_token");
      const direction = "outgoing";
      const getBankDetailsRequest = new WebSocketRequestModel(
        verb,
        url,
        body,
        token,
        direction
      );
      this.webSocketService.sendMessage(getBankDetailsRequest);
    }
  }
  /**
   * This function will find the appropriate bank and add the details
   * under the `.institutionDetails` field of the bank.
   * @param bankDetails
   */
  updateBankDetails(bankDetails: YapilyBankDetailsModel) {
    const bank = this.banks.find((b) => b.institution === bankDetails.id);
    // If the bank is found, update its institutionDetails property
    if (bank) {
      bank.institutionDetails = bankDetails;
      bank.accounts = []; //preparing next step
    } else {
      console.error("Bank not found:", bankDetails.id);
    }
    this.getAccountsFromBank(bank);
  }

  /**
   * Fetches all accounts from a bank and make the WS call
   * @param bank to which to fetch the accounts from
   */
  getAccountsFromBank(bank: YapilyBankModel) {
    const verb = "GET";
    const url = `${env.yapily_api}${ENDPOINTS.ALL_ACCOUNTS_FROM_BANK(
      bank.institution
    )}`;
    const body = "";
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getAllAccountsFromBank = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getAllAccountsFromBank);
  }

  postNewConsent(links) {
    const verb = "POST";
    const url = `${env.yapily_api}/yapily/consent`;
    const body = JSON.stringify(links);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getAllAccountsFromBank = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getAllAccountsFromBank);
  }
  deleteBank(account: IGenericAccount) {
    const verb = "DELETE";
    const url = `${env.yapily_api}/yapily/consent/${account.id}`;
    const body = ""
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const DeletetAllAccountsFromBank = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(DeletetAllAccountsFromBank);
  }

  deleteBankState(accountsId : string[]){
    this.store.dispatch('banks.delete.one.state' , accountsId)
  }

  patchAuthorization(bank: YapilyBankModel) {
    const verb = "PATCH";
    const url = `${env.yapily_api}/yapily/consent/${bank.institution}`;
    const body = JSON.stringify(bank);
    const token = localStorage.getItem("aurelia_token");
    const direction = "outgoing";
    const getAllAccountsFromBank = new WebSocketRequestModel(
      verb,
      url,
      body,
      token,
      direction
    );
    this.webSocketService.sendMessage(getAllAccountsFromBank);
  }


  async getTransactionForAccount(account){
      let request = await this.yapilyHttpClient.fetch(`/yapily/bank/account/${account.id}/transactions`)
      let isok200 = await checkResponseStatus(request)
      let transaction = await isok200.json()
      if (!transaction) {
        return []
      }
      return transaction

  }

  transformTransaction(transaction: YapilyTransactionModel): IGenericTransaction {
    try{
    let result: {imagePath: string, brandName: string} = this.getBrandPicture(transaction);
    let identification;
    let transactionName
    if (transaction.payeeDetails) {
      if (transaction.payeeDetails.accountIdentifications[0].type === "IBAN"){

        identification = transaction.payeeDetails.accountIdentifications[0].identification
      }
      else {
        identification = transaction.payeeDetails.accountIdentifications[1].identification
      }
      if(!result.brandName) {
        transactionName = transaction.payeeDetails.name
      }
      else {
        transactionName = transaction.description || transaction.reference
      }
    }

    else {
      identification = ""

      if (!result.brandName) {
        transactionName = transaction.description
      }

      else {
        transactionName = result.brandName
      }
    }


    let transformedTransaction: IGenericTransaction = {

      urlPic : result.imagePath,
      senderName : transactionName,
      _id : transaction.id,
      senderNote : transaction.description || transaction.reference,
      currency : transaction.transactionAmount.currency,
      amount : transaction.transactionAmount.amount,
      createdAt : new Date(transaction.date) || new Date(transaction.bookingDateTime),
      updatedAt : new Date(transaction.date) || new Date(transaction.bookingDateTime),
      senderIdentification :  identification, //transaction.payeeDetails.accountIdentifications[0].identification ||
      walletId : "",
      transactionId : ""
    };

    

    return transformedTransaction;
    }catch(e){
      console.log(e)
    }
  
  }

  async getPictureFromInstitutionId(institutionId){
    try{
      let request = await this.yapilyHttpClient.fetch(`/yapily/bank/${institutionId}`)
      let is200ok = await checkResponseStatus(request)
      let picture =  await is200ok.json()
      return picture.media[0].source || ""
    } catch(e){
      console.log(e)
    }
  }

  /**
   * This function will add the accounts to the appropriate bank
   * then will trigger a mutation on the state for the banks
   * @param bankAccounts
   */
  async updateAccountsFromBank(bankData) {
    try {
      for (let i = 0; i < bankData.data.length; i++) {
        for (let g = 0; g < bankData.data[i].account.length; g++) {
          const account = bankData.data[i].account[g];
          const isError = (account.accountIdentifications && account.accountIdentifications[0].identification) ? false : true;
          account.identification = isError ? this.i18n.tr("components.card-banka-error.bank_consent_expired") : account.accountIdentifications[0].identification;
          account.isError = isError;
          account.isBlockchain = false;
          account.picture = await this.getPictureFromInstitutionId(account.institutionId);
          
          if (Array.isArray(account.accountNames) && account.accountNames.length > 0 && account.accountNames[0].name) {
              account.name = account.accountNames[0].name;
          } else {
              account.name = account.accountIdentifications[0].identification;
          }

          this.store.dispatch("banks.accounts.add.one", account);

          if (!account.transactions) {
            try {
              let transactions = await this.getTransactionForAccount(account);
              const state: State | null = await localforage.getItem('State');

              if (state) {
                // Update transactions
                account.transactions = transactions.map(transaction => {
                    return this.transformTransaction(transaction);
                });

                // Find account's index in state.wallets
                const accountIndex = state.wallets.findIndex(existingAccount => existingAccount.id === account.id);

                // if account exist in state.wallets, update transactions list
                if (accountIndex !== -1) {
                  const updatedWallets = [...state.wallets];
                  updatedWallets[accountIndex] = account;

                  // Update localStorage
                  await localforage.setItem('State', { ...state, wallets: updatedWallets });
                }
              }
            } catch (error) {
                console.log(error);
            }
          }
        }
      }
    } catch (e) {
        console.log(e);
    }
  }
  
  /**
   * Will compare unique institution and keep only once in array
   * @param banksInDouble array of bank with potentially multiple time same institution
   * @returns cleaned array without double institution
   */
  cleanDoubleBanks(banksInDouble) {
    let singleBanks = [];
    let singleBanksModelToReturn = [];

    for (let i = 0; i < banksInDouble.length; i++) {
      if (!singleBanks.includes(banksInDouble[i].institution)) {
        singleBanks.push(banksInDouble[i].institution);
        singleBanksModelToReturn.push(banksInDouble[i]);
      }
    }

    return singleBanksModelToReturn;
  }

  detached() {
    this.subscriptions.forEach((subscription) => subscription.dispose());
  }


  

  getBrandPicture(tx: any) {
  
    let reference = tx.reference || tx.description;
    const lowercaseReference = reference.toLowerCase();
    try {

      const brandMap: { [key: string]: { imagePath: string, brandName: string } } = {
        /* Others */
        "aliexpress": { imagePath: "img/brands/aliexpress.png", brandName: "AliExpress" },
        "kabel": { imagePath: "img/brands/kabel.jpg", brandName: "Kabel" },
        "la poste": { imagePath: "img/brands/laposte.png", brandName: "La Poste" },
        "bolt": {imagePath: "img/brands/bolt_eu.jpg", brandName: "Bolt"},
        "debuck": {imagePath: "img/brands/debuck_technolgies.png", brandName: "Debuck Technologies"},
        /* TELECOMs */ 
        "bouygues": {imagePath: "img/brands/bouygues.png", brandName: "Bouygues Telecom"},
        "o2": {imagePath: "img/brands/o2.png", brandName: "O²"},
        "orange": {imagePath: "img/brands/orange.png", brandName: "Orange"},
        "proximus": {imagePath: "img/brands/proximus.png", brandName: "Proximus"},
        "t-online": {imagePath: "img/brands/t_online.png", brandName: "T-Online"},
        "tadaam": {imagePath: "img/brands/tadaam.png", brandName: "Tadaam"},
        "telenet": {imagePath: "img/brands/telenet.png", brandName: "Telenet"},
        "sfr": {imagePath: "img/brands/sfr.png", brandName: "SFR"},
        "vodafone": {imagePath: "img/brands/vodafone.png", brandName: "Vodafone"},
        "voo": {imagePath: "img/brands/voo.png", brandName: "VOO"},
        "free": {imagePath: "img/brands/free.png", brandName: "Free"},
        "discord": {imagePath: "img/brands/discord.png", brandName: "Discord"},
        /* restaurants */
        "o-tacos": {imagePath: "img/brands/otacos.png", brandName: "O-Tacos"},
        "mcdonald": {imagePath: "img/brands/mcdonald.png", brandName: "McDonald"},
        "quick": {imagePath: "img/brands/quick.png", brandName: "Quick"},
        "uber eats": {imagePath: "img/brands/uber_eats.png", brandName: "Uber Eats"},
        "starbucks": {imagePath: "img/brands/starbucks.png", brandName: "Starbucks"},
        "burger king": {imagePath: "img/brands/burger_king.png", brandName: "Burger King"},
        "kfc": {imagePath: "img/brands/kfc.png", brandName: "KFC"},
        "exki": {imagePath: "img/brands/exki.png", brandName: "EXKi"},
        "pizza hut": {imagePath: "img/brands/pizzahut.png", brandName: "Pizza Hut"},
        "pianofabriek": {imagePath: "img/brands/pianofabriek.png", brandName: "GC Pianofabriek"},
        "cook & book": {imagePath: "img/brands/cookandbook.png", brandName: "Cook & Book"},
        "schievelavabo": {imagePath: "img/brands/schievelavabo.png", brandName: "Schievelavabo"},
        "makisu": {imagePath: "img/brands/makisu.png", brandName: "Makisu"},
        "zanzibar 1348": {imagePath: "img/brands/zanzibar.png", brandName: "Zanzibar"},
        "deliveroo": {imagePath: "img/brands/deliveroo.png", brandName: "Deliveroo"},
        "belchicken": {imagePath: "img/brands/belchicken.png", brandName: "Belchicken"},
        "be burger": {imagePath: "img/brands/beburger.png", brandName: "BeBurger"},
        "green mango": {imagePath: "img/brands/greenmango.png", brandName: "Green Mango"},
        "takeway": {imagePath: "img/brands/takeaway.png", brandName: "TakeAway"},
        /* transportation */
        "sncb": {imagePath: "img/brands/sncb.png", brandName: "SNCB"},
        "stib": {imagePath: "img/brands/stib.png", brandName: "STIB"},
        "uber": {imagePath: "img/brands/uber.png", brandName: "Uber"},
        "poppy": {imagePath: "img/brands/poppy.png", brandName: "Poppy"},
        "miles": {imagePath: "img/brands/miles.png", brandName: "Miles"},
        "eurostar": {imagePath: "img/brands/eurostar.png", brandName: "Eurostar"},
        /* Entertainment */
        "netflix": {imagePath: "img/brands/netflix.png", brandName: "Netflix"},
        "spotify": {imagePath: "img/brands/spotify.png", brandName: "Spotify"},
        "steam": {imagePath: "img/brands/steam.png", brandName: "Steam"},
        /* Big Retailers */
        "amazon": {imagePath: "img/brands/amazon.png", brandName: "Amazon"},
        "microsoft": {imagePath: "img/brands/microsoft.png", brandName: "Microsoft"},
        "google": {imagePath: "img/brands/google.png", brandName: "Google"},
        "sony": {imagePath: "img/brands/sony.png", brandName: "Sony"},
        "fiverr": {imagePath: "img/brands/fiverr.png", brandName: "Fiverr"},
        "kinepolis": {imagePath: "img/brands/kinepolis.png", brandName: "Kinepolis"},
        "okaidi": {imagePath: "img/brands/okaidi.png", brandName: "Okaidi"},
        "cora": {imagePath: "img/brands/cora.png", brandName: "Cora"},
        "auchan": {imagePath: "img/brands/auchan.png", brandName: "Auchan"},
        "cirque royal": {imagePath: "img/brands/cirqueroyal.png", brandName: "Cirque Royal"},
        "carrefour": {imagePath: "img/brands/carrefour.png", brandName: "Carrefour"},
        "wish": {imagePath: "img/brands/wish.png", brandName: "Wish"},
        "brico": {imagePath: "img/brands/brico.png", brandName: "Brico"},
        "lidl": {imagePath: "img/brands/lidl.png", brandName: "Lidl"},
        "aldi": {imagePath: "img/brands/aldi.png", brandName: "Aldi"},
        "delhaize": {imagePath: "img/brands/delhaize.png", brandName: "Delhaize"},
        "proxy": {imagePath: "img/brands/proxy.png", brandName: "Delhaize"},
        "ikea": {imagePath: "img/brands/ikea.png", brandName: "Ikea"},
        /* GAS STATION */
        "shell": {imagePath: "img/brands/shell.png", brandName: "Shell"},
        "total": {imagePath: "img/brands/total.png", brandName: "Total"},
        "esso": {imagePath: "img/brands/esso.png", brandName: "Esso"},
        "q8": {imagePath: "img/brands/q8.png", brandName: "Q8"},
        /* GENERIC */
        "pizza": {imagePath: "img/brands/pizza.png", brandName: "Pizza!"},
        "pharma": {imagePath: "img/brands/pharmacie.png", brandName: "Medic"},
        "parking": {imagePath: "img/brands/parking.png", brandName: "Parking"},
        "park bru": {imagePath: "img/brands/parking.png", brandName: "Parking BXL"},
        "spf finances": {imagePath: "img/brands/spf_finance.png", brandName: "SPF Finances"},
        "onss": {imagePath: "img/brands/onss.png", brandName: "ONSS"},
        /* Payment providers */
        "paypal": {imagePath: "img/brands/paypal.png", brandName: "Paypal"},
        "payconiq": {imagePath: "img/brands/payconiq.png", brandName: "Payconiq"},
        "bancontact": {imagePath: "img/brands/bancontact.png", brandName: "Bancontact"},
      };


      for (const key in brandMap) {
        if (lowercaseReference.includes(key.toLowerCase())) {
            return brandMap[key];
        }
      }


      return { imagePath: "img/bank_account_white.png", brandName: undefined };
    } catch (e) {
        console.error("Erreur lors de la recherche de l'image de la marque:", e);
        return { imagePath: "img/bank_account_white.png", brandName: undefined };
    }
  }
}